import { useNavigate, generatePath } from "react-router-dom";

const CourseTable = ({ course, selectedRole }) => {
  const navigate = useNavigate();

  const handleNavigate = (slug) => {
    // Create a properly formatted path
    const path =
      `/practice/${selectedRole.toLowerCase()}/${course.courseName.toLowerCase()}/${slug}`
        .replace(/%20/g, "-") // Replace any encoded spaces with hyphens
        .replace(/\s+/g, "-"); // Replace any regular spaces with hyphens

    navigate(path);
  };

  return (
    <section className="mb-8">
      <h2 className="text-2xl font-semibold text-white mb-2 text-center">
        {course.courseName} {course.completedChallenges}/
        {course.totalChallenges}
      </h2>
      <div className="text-white mb-2 text-center">{course.description}</div>
      <div className="overflow-x-auto flex flex-col justify-center lg:items-center pb-12 text-xs lg:text-sm text-white font-light">
        <table className="table w-7/12">
          <thead className="border-b">
            <tr>
              <th className="bg-zinc-800">Problem</th>
              <th className="bg-zinc-800">Difficulty</th>
              <th className="bg-zinc-800">Status</th>
            </tr>
          </thead>
          <tbody>
            {course.challenges.length > 0 ? (
              course.challenges.map((item, itemIndex) => (
                <tr
                  key={itemIndex}
                  className="hover:bg-zinc-700 cursor-pointer"
                  onClick={() => handleNavigate(item.slug)}
                >
                  <td
                    className={`bg-zinc-${itemIndex % 2 === 0 ? "900" : "800"}`}
                  >
                    {item.problem ? item.problem : "No problem"}
                  </td>
                  <td
                    className={`bg-zinc-${itemIndex % 2 === 0 ? "900" : "800"}`}
                  >
                    {item.difficulty ? item.difficulty : "No difficulty"}
                  </td>
                  <td
                    className={`bg-zinc-${itemIndex % 2 === 0 ? "900" : "800"}`}
                  >
                    <span
                      className={`badge ${
                        item.status === "In Progress"
                          ? "badge-warning"
                          : item.status === "Not Started"
                          ? "badge-error"
                          : "badge-secondary"
                      }`}
                    >
                      {item.status}
                    </span>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3" className="bg-zinc-900 text-center">
                  No challenges available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default CourseTable;
