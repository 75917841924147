import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  fetchPracticeProblems,
  setSelectedRole,
} from "../components/features/courses/courseSlice";

const usePracticeData = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { selectedRole, practiceData, showSpinner } = useSelector(
    (state) => state.courses
  );

  const [initialRole, setInitialRole] = useState("{Your Role}");
  const [selectedCourse, setSelectedCourse] = useState(null);

  const roleFromUrl = location.pathname.split("/")[2];

  useEffect(() => {
    if (roleFromUrl) {
      const formattedRole = roleFromUrl
        .split("-")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(" ");

      dispatch(setSelectedRole(formattedRole));
      setInitialRole(formattedRole);
    }
    dispatch(fetchPracticeProblems());
  }, [dispatch, roleFromUrl]);

  const handleRoleChange = (role) => {
    console.log("hi: ", role);
    const formattedRole = roleFromUrl
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");

    dispatch(setSelectedRole(formattedRole));
    setSelectedCourse(null);
    setInitialRole(formattedRole);
    navigate(`/practice/${role.toLowerCase()}`);
  };

  return {
    initialRole,
    selectedRole,
    practiceData,
    showSpinner,
    selectedCourse,
    setSelectedCourse,
    handleRoleChange,
  };
};

export default usePracticeData;
