import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deployMetadata,
  deployAdvent,
} from "../../features/courses/courseSlice";
import {
  FaCheckCircle,
  FaTimesCircle,
  FaSpinner,
  FaExclamationCircle,
  FaInfoCircle,
  FaCode,
  FaRocket,
  FaGithub,
} from "react-icons/fa";

const DeployPage = () => {
  const { "package-name": packageName } = useParams();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const [isDeploying, setIsDeploying] = useState(false);
  const [deploymentResponse, setDeploymentResponse] = useState(null);

  const handleStartDeployment = async () => {
    setIsDeploying(true);
    try {
      let response;
      if (packageName === "advent-2024") {
        response = await dispatch(deployAdvent(packageName)).unwrap();
      } else {
        response = await dispatch(deployMetadata(packageName)).unwrap();
      }

      setDeploymentResponse(response);
    } catch (error) {
      setDeploymentResponse({ error: error.message });
    }
    setIsDeploying(false);
  };

  const reRouteToLogin = () => {
    window.open("/auth", "_blank");
  };

  const getStatusBadge = () => {
    if (deploymentResponse?.error) {
      return (
        <div className="flex items-center gap-2 badge badge-error badge-lg">
          <FaTimesCircle className="w-5 h-5" />
          Deployment Failed
        </div>
      );
    }
    return (
      <div className="flex items-center gap-2 badge badge-success badge-lg">
        <FaCheckCircle className="w-5 h-5" />
        Deployment Successful
      </div>
    );
  };

  const renderDeploymentStatus = () => {
    if (isDeploying) {
      return (
        <div className="card bg-zinc-800 shadow-xl border border-zinc-700 mt-8">
          <div className="card-body items-center text-center">
            <FaSpinner className="w-16 h-16 text-purple-400 animate-spin mb-4" />
            <p className="text-xl">Deploying {packageName}...</p>
          </div>
        </div>
      );
    }

    if (deploymentResponse) {
      return (
        <div className="card bg-zinc-800 shadow-xl border border-zinc-700 mt-8">
          <div className="card-body">
            {/* Header Section */}
            <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
              <div>
                <h2 className="card-title text-2xl mb-2 text-purple-300">
                  Deployment Status
                </h2>
                <p className="text-zinc-600">Package: {packageName}</p>
              </div>
              {getStatusBadge()}
            </div>

            <div className="divider border-zinc-700"></div>

            {/* Details Section */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Deployment Info */}
              <div className="card bg-zinc-700/50 border border-zinc-600">
                <div className="card-body">
                  <h3 className="card-title text-lg flex items-center gap-2 text-purple-300">
                    <FaInfoCircle />
                    Deployment Information
                  </h3>
                  <div className="space-y-2 text-zinc-600">
                    <p>
                      <span className="font-semibold">Deployment ID:</span>{" "}
                      {deploymentResponse?.deploymentId || "N/A"}
                    </p>
                    <p>
                      <span className="font-semibold">Start Time:</span>{" "}
                      {deploymentResponse?.startTime ||
                        new Date().toISOString()}
                    </p>
                    <p>
                      <span className="font-semibold">Status:</span>{" "}
                      {deploymentResponse?.error ? "Failed" : "Success"}
                    </p>
                  </div>
                </div>
              </div>

              {/* Technical Details */}
              <div className="card bg-zinc-700/50 border border-zinc-600">
                <div className="card-body">
                  <h3 className="card-title text-lg flex items-center gap-2 text-purple-300">
                    <FaCode />
                    Technical Details
                  </h3>
                  <div className="space-y-2 text-zinc-600">
                    <p>
                      <span className="font-semibold">Instance URL:</span>{" "}
                      <a
                        href={
                          deploymentResponse?.instanceUrl ||
                          user?.[0]?.instanceUrl
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-purple-400 hover:text-purple-500 transition-colors"
                      >
                        {deploymentResponse?.instanceUrl ||
                          user?.[0]?.instanceUrl ||
                          "N/A"}
                      </a>
                    </p>
                    <p>
                      <span className="font-semibold">Package Type:</span>{" "}
                      {deploymentResponse?.type || packageName}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Error Alert */}
            {deploymentResponse?.error && (
              <div className="alert alert-error mt-6 bg-red-900/50 border border-red-700">
                <FaExclamationCircle className="w-6 h-6" />
                <div>
                  <h4 className="font-bold">Deployment Error</h4>
                  <p className="text-sm">{deploymentResponse.error}</p>
                </div>
              </div>
            )}
          </div>
        </div>
      );
    }

    return null;
  };

  const url =
    packageName === "advent-2024"
      ? "https://github.com/CampApex/Advent-of-Salesforce-Development"
      : "https://github.com/CampApex/FlowingIntoApex";

  return (
    <div className="min-h-screen bg-zinc-900 text-zinc-600">
      <div className="container mx-auto px-4 py-8">
        {/* Original UI */}
        <div className="card !bg-zinc-800 shadow-xl border border-zinc-700">
          <div className="card-body items-center text-center">
            <FaRocket className="w-16 h-16 text-purple-400 mb-4" />
            <a
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center gap-2 text-purple-400 hover:text-purple-500 transition-colors"
            >
              <FaGithub className="w-5 h-5" />
              <span className="underline underline-offset-2">
                View on GitHub
              </span>
            </a>
            <p className="text-zinc-600 mb-6">Package: {packageName}</p>
            {user?.[0]?.instanceUrl ? (
              <button
                className="btn btn-lg normal-case bg-purple-400 hover:bg-purple-500 text-zinc-900 border-none min-w-[200px]"
                onClick={handleStartDeployment}
                disabled={isDeploying}
              >
                {isDeploying ? "Deploying..." : "Start Deployment"}
              </button>
            ) : (
              <button
                className="btn btn-lg normal-case bg-purple-400 hover:bg-purple-500 text-zinc-900 border-none min-w-[200px]"
                onClick={reRouteToLogin}
              >
                Login to Deploy
              </button>
            )}
          </div>
        </div>

        {/* Deployment Status */}
        {renderDeploymentStatus()}
      </div>
    </div>
  );
};

export default DeployPage;
