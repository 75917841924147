import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setOpenCollection } from "../../features/courses/courseSlice";

function CollectionItem({ collection }) {
  const { openCollection } = useSelector((state) => state.courses);
  const dispatch = useDispatch();

  const isActive = openCollection?.name === collection?.name;

  const handleClick = (e) => {
    e.preventDefault();
    dispatch(setOpenCollection(collection));
  };

  return (
    <a
      className={`tab tab-bordered ${
        isActive
          ? "tab-active lg:text-lg text-xs !text-white"
          : "lg:text-lg text-xs"
      }`}
      onClick={handleClick}
      data-toggle="tab"
      href={`#${collection.name}`}
      role="tablist"
    >
      {collection?.name}
    </a>
  );
}

export default CollectionItem;
