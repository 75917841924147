import { FaCampground } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { SiSalesforce } from "react-icons/si";
import { useState, useEffect } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { logout } from "../features/auth/authSlice";

import PropTypes from "prop-types";
import SimpleNav from "./SimpleNav";

function Navbar({ title, user }) {
  const dispatch = useDispatch();
  function handleLogout() {
    dispatch(logout());
  }

  const [isDesktop, setDesktop] = useState(window.innerWidth > 1050);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 1050);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  const { pathname } = useLocation();
  if (pathname.includes("lesson"))
    return <SimpleNav title={title} user={user} />;

  let showGlow = true;
  showGlow = pathname !== "/" && pathname.includes("/auth") && user;
  const hideNav = () => {
    const elem = document.activeElement;
    if (elem) {
      elem?.blur();
    }
  };

  return (
    <div className={`navbar bg-black relative ${showGlow ? "main-nav" : ""}`}>
      <div className="navbar-start lg:pl-8">
        <FaCampground className="inline pr-2 text-3xl" />
        <Link to="/" className="lg:text-lg font-bold align-middle">
          {title}
        </Link>
      </div>
      <div className="navbar-end fill-current lg:mr-10">
        <Link
          to="/courses"
          className="text-xs lg:text-base mr-2 lg:mr-5 hover:text-purple-300"
        >
          Learn
        </Link>
        <Link
          className="text-xs lg:text-base mr-2 lg:mr-5 hover:text-purple-300"
          to="/practice/flow-into-apex"
        >
          Practice
        </Link>
        <Link
          className="text-xs lg:text-base mr-2 lg:mr-5 hover:text-purple-300"
          to="/projects"
        >
          Projects
        </Link>
        <Link
          className="text-xs lg:text-base mr-2 lg:mr-10 hover:text-purple-300"
          to="/compete"
        >
          Compete
        </Link>
        {/* <div className="dropdown dropdown-end "> */}
        {/* <div tabIndex={0} role="button" className="mr-3 ">
            <GiHamburgerMenu className="text-xl hover:text-primary" />
          </div> */}

        {/* </div> */}
        {user ? (
          <div className="dropdown dropdown-end ">
            <div tabIndex={0} role="button" className="mr-3 ">
              <SiSalesforce color="#009EDB" className="inline pr-2 text-3xl" />
            </div>
            <ul
              tabIndex={0}
              className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-black rounded-box w-52"
            >
              <li onClick={hideNav}>
                <Link className="text-base" to="/auth">
                  Profile
                </Link>
              </li>
              <li onClick={handleLogout}>
                <Link className="text-base">Logout</Link>
              </li>
            </ul>
          </div>
        ) : (
          <>
            {isDesktop ? (
              <Link className="text-base" target="_blank" to="/auth">
                Login
              </Link>
            ) : (
              <Link className="text-base pr-4" target="_blank" to="/auth">
                <SiSalesforce></SiSalesforce>
              </Link>
            )}
          </>
        )}
      </div>
    </div>
  );
}

Navbar.defaultProps = {
  title: "Camp Apex",
};

Navbar.propTypes = {
  title: PropTypes.string,
};

export default Navbar;
