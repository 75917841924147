import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import competitionService from "./competitionService";

const initialState = {
  competitions: null,
  showSpinner: false,
  globalLeaderboard: null,
  pointsDistribution: null,
  registerDisplayNameMessage: null,
  contest: [],
  userStats: null,
  registrationStatus: null,
  challenge: null,
  solution: null,
  executionStatus: null,
  challengeProgress: null,
  generalError: null,
  contestCompleted: null,
  adventContests: null,
  adventLoading: false,
  adventError: null,
  adventLeaderboard: null,
};

export const getAdventContests = createAsyncThunk(
  "competition/getAdventContests",
  async (_, thunkAPI) => {
    try {
      return await competitionService.getAdventContests();
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response?.data || { Error: "Failed to fetch advent contests" }
      );
    }
  }
);

export const getAdventChallenge = createAsyncThunk(
  "getAdventChallenge/get",
  async ({ tag, slug }, thunkAPI) => {
    try {
      return await competitionService.getAdventChallenge(tag, slug);
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const submitChallenge = createAsyncThunk(
  "submitChallenge/post",
  async (payload, thunkAPI) => {
    try {
      return await competitionService.submitChallenge(payload);
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue();
    }
  }
);

export const runCode = createAsyncThunk(
  "submitCode/post",
  async (payload, thunkAPI) => {
    try {
      return await competitionService.runCode(payload);
    } catch (error) {
      if (error.response && error.response.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
      return thunkAPI.rejectWithValue({ Error: "An error occurred." });
    }
  }
);

export const setSolution = createAsyncThunk("solution", (value, thunkAPI) => {
  return value;
});

export const semanticValidationException = createAsyncThunk(
  "",
  async (payload, thunkAPI) => {
    let errorMsg = payload;
    payload = {
      data: {
        success: false,
        compileProblem: errorMsg,
      },
    };
    return payload;
  }
);

export const getContestChallenge = createAsyncThunk(
  "getContestChallenge/get",
  async (challengeId, thunkAPI) => {
    try {
      return await competitionService.getContestChallenge(challengeId);
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getSubmissions = createAsyncThunk(
  "getSubmissions/get",
  async (challengeId, thunkAPI) => {
    try {
      return await competitionService.getSubmissions(challengeId);
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const register = createAsyncThunk(
  "register/post",
  async ({ contestId, accessCode }, thunkAPI) => {
    try {
      return await competitionService.register(contestId, accessCode);
    } catch (error) {
      if (error.response && error.response.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
      return thunkAPI.rejectWithValue({ Error: "An error occurred." });
    }
  }
);

export const registerDisplayName = createAsyncThunk(
  "registerDisplayName/post",
  async (displayName, thunkAPI) => {
    try {
      return await competitionService.registerDisplayName(displayName);
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getAllContests = createAsyncThunk(
  "getAllContests/get",
  async (_, thunkAPI) => {
    try {
      return await competitionService.getAllContests();
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getGlobalLeaderboard = createAsyncThunk(
  "getGlobalLeaderboard/get",
  async (_, thunkAPI) => {
    try {
      return await competitionService.getGlobalLeaderboard();
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getAdventLeaderboard = createAsyncThunk(
  "getAdventLeaderboard/get",
  async (_, thunkAPI) => {
    try {
      return await competitionService.getAdventLeaderboard();
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getPointsDistribution = createAsyncThunk(
  "getPointsDistribution/get",
  async (type, thunkAPI) => {
    try {
      return await competitionService.getPointsDistribution(type);
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getContest = createAsyncThunk(
  "getContest/get",
  async (tag, thunkAPI) => {
    try {
      return await competitionService.getContest(tag);
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getStats = createAsyncThunk(
  "getStats/get",
  async (_, thunkAPI) => {
    try {
      return await competitionService.getStats();
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const executeCodeReset = createAsyncThunk(
  "executeCodeReset/post",
  async (challengeId, thunkAPI) => {
    try {
      return await competitionService.executeCodeReset(challengeId);
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const logAction = createAsyncThunk(
  "logAction",
  async (action, thunkAPI) => {
    try {
      return await competitionService.logAction(action);
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const competitionSlice = createSlice({
  name: "competitions",
  initialState,
  reducers: {
    clearRegisterDisplayNameMessage: (state) => {
      state.registerDisplayNameMessage = null;
    },
    resetCompetitionState: (state) => {
      // Reset any state that might cause issues between routes
      state.registerDisplayNameMessage = null;
      state.generalError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllContests.pending, (state) => {
        state.showSpinner = true;
        state.competitions = null;
        state.generalError = null;
      })
      .addCase(getAllContests.fulfilled, (state, action) => {
        state.showSpinner = false;
        state.competitions = action.payload;
      })
      .addCase(getGlobalLeaderboard.fulfilled, (state, action) => {
        state.globalLeaderboard = action.payload.leaderboard;
      })
      .addCase(getPointsDistribution.fulfilled, (state, action) => {
        state.pointsDistribution = action.payload;
      })
      .addCase(getContest.pending, (state, action) => {
        state.generalError = null;
        state.showSpinner = true;
        // state.generalError = null;
      })
      .addCase(getContest.fulfilled, (state, action) => {
        state.contest = action.payload;
        state.showSpinner = false;
      })
      .addCase(registerDisplayName.fulfilled, (state, action) => {
        state.registerDisplayNameMessage = action.payload.Error;
        state.userStats.userInfo.displayName =
          action.payload?.NewDisplayName ||
          state.userStats.userInfo.displayName;
        state.showSpinner = false;
      })
      .addCase(registerDisplayName.pending, (state, action) => {
        state.showSpinner = true;
      })
      .addCase(getStats.fulfilled, (state, action) => {
        state.userStats = action.payload;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.registrationStatus = action.payload;
        state.showSpinner = false;
      })
      .addCase(register.rejected, (state, action) => {
        state.generalError = action.payload.Error;
      })
      .addCase(getContestChallenge.fulfilled, (state, action) => {
        state.executionStatus = null;
        state.generalError = action.payload?.Error ? action.payload : null;
        state.challenge = action.payload.getActiveChallenge;
        state.challengeProgress = action.payload.submission;
        state.showSpinner = false;
      })
      .addCase(getContestChallenge.pending, (state, action) => {
        state.generalError = null;
        state.showSpinner = true;
      })
      .addCase(setSolution.fulfilled, (state, action) => {
        state.solution = action.payload;
      })
      .addCase(semanticValidationException.fulfilled, (state, action) => {
        state.executionStatus = action.payload;
      })
      .addCase(semanticValidationException.pending, (state, action) => {
        state.executionStatus = null;
      })
      .addCase(runCode.fulfilled, (state, action) => {
        state.executionStatus = action.payload;
        state.showSpinner = false;
        state.generalError = action.payload?.Error;
        state.contestCompleted = action.payload?.overallProgress
          ?.allChallengesCompleted
          ? action.payload?.overallProgress
          : null;
      })
      .addCase(runCode.pending, (state, action) => {
        state.showSpinner = true;
        state.executionStatus = null;
      })
      .addCase(runCode.rejected, (state, action) => {
        state.generalError = action.payload?.Error;
      })
      .addCase(executeCodeReset.fulfilled, (state, action) => {
        // state.showSpinner = false;
      })
      .addCase(executeCodeReset.pending, (state, action) => {
        state.showSpinner = true;
      })
      .addCase(getAdventContests.pending, (state) => {
        state.generalError = null;
        state.adventLoading = true;
        state.adventError = null;
        state.contestCompleted = null;
        state.executionStatus = null;
        state.challenge = null;
        state.challengeProgress = null;
      })
      .addCase(getAdventContests.fulfilled, (state, action) => {
        state.adventLoading = false;
        state.adventContests = action.payload;
      })
      .addCase(getAdventContests.rejected, (state, action) => {
        state.adventLoading = false;
        state.adventError = action.payload;
      })
      .addCase(getAdventChallenge.pending, (state) => {
        state.showSpinner = true;
        state.contestCompleted = null;
        state.generalError = null;
        state.executionStatus = null;
        state.challenge = null;
        state.challengeProgress = null;
      })
      .addCase(getAdventChallenge.fulfilled, (state, action) => {
        state.challenge = action.payload.getActiveChallenge;
        state.challengeProgress = action.payload.submission;
        state.submissionHistory = action.payload.submissionHistory;
        state.showSpinner = false;
        state.generalError = action.payload?.Error ? action.payload : null;
      })
      .addCase(getAdventChallenge.rejected, (state, action) => {
        state.showSpinner = false;
        state.generalError = action.payload;
      })
      .addCase(getAdventLeaderboard.fulfilled, (state, action) => {
        state.adventLeaderboard = action.payload;
      });
  },
});

export default competitionSlice.reducer;
export const { clearRegisterDisplayNameMessage } = competitionSlice.actions;
export const { resetCompetitionState } = competitionSlice.actions;
