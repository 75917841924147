import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAdventLeaderboard } from "../../features/competition/competitionSlice";

const AdventLeaderboard = () => {
  const dispatch = useDispatch();
  const { adventLeaderboard } = useSelector((state) => state.competitions);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await dispatch(getAdventLeaderboard());
      setIsLoading(false);
    };
    fetchData();
  }, [dispatch]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  const leaderboard = adventLeaderboard?.leaderboard || [];

  return (
    <div className="bg-zinc-900 p-6 rounded-lg shadow-lg">
      <div className="overflow-x-auto">
        <table className="w-full table-auto">
          <thead>
            <tr className="text-left border-b border-zinc-700">
              <th className="py-3 px-4 text-zinc-400 font-semibold">Rank</th>
              <th className="py-3 px-4 text-zinc-400 font-semibold">Name</th>
              <th className="py-3 px-4 text-zinc-400 font-semibold text-right">
                Points
              </th>
              <th className="py-3 px-4 text-zinc-400 font-semibold text-right">
                Challenges
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-zinc-800">
            {leaderboard.map((entry) => (
              <tr
                key={entry._id}
                className="hover:bg-zinc-800/50 transition-colors duration-150"
              >
                <td className="py-3 px-4">
                  <span className="inline-flex items-center justify-center w-8 h-8 rounded-full bg-zinc-800 text-zinc-200">
                    {entry.rank}
                  </span>
                </td>
                <td className="py-3 px-4 text-purple-300 font-semibold">
                  {entry.displayName}
                </td>
                <td className="py-3 px-4 text-right text-zinc-200">
                  {entry.totalPoints.toLocaleString()}
                </td>
                <td className="py-3 px-4 text-right text-zinc-400">
                  {entry.challengesCompleted}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {leaderboard.length === 0 && (
        <div className="text-center py-8 text-zinc-400">
          No participants yet. Be the first to join!
        </div>
      )}
    </div>
  );
};

export default AdventLeaderboard;
