import React, { useEffect } from "react";

const SenjaFeedbackCollector = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://widget.senja.io/js/iframeResizer.min.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      if (window.iFrameResize) {
        window.iFrameResize(
          { log: false, checkOrigin: false },
          "#senja-collector-iframe"
        );
      }
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="bg-zinc-800 p-8 rounded-lg text-center mb-4 lg:mb-0">
      <iframe
        id="senja-collector-iframe"
        src="https://senja.io/p/camp-apex/r/d74Bxu?mode=embed&theme=dark"
        allow="camera;microphone"
        title="Senja form"
        frameBorder="0"
        scrolling="no"
        width="100%"
        height="700"
      />
    </div>
  );
};

export default SenjaFeedbackCollector;
