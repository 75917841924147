import React from "react";
import { Link, useLocation } from "react-router-dom";
import ActionButton from "./ActionButton";

function LessonFooter({ lesson }) {
  const location = useLocation();

  // if (!lesson?.[0]) {
  //   return null;
  // }

  const isPracticeRoute = location.pathname.startsWith("/practice/");
  const hasSlug = Boolean(lesson[0]?.slug);

  let moduleType = "Lesson";
  if (
    lesson[0]?.editorlessChallenge ||
    lesson[0]?.collectionDetails?.type === "practice"
  ) {
    moduleType = "Challenge";
  }

  const getNavigationUrl = (id, slug) => {
    if (isPracticeRoute && hasSlug && slug) {
      const pathParts = location.pathname.split("/");
      const role = pathParts[2];
      const course = pathParts[3];
      return `/practice/${role}/${course}/${slug}`;
    }
    return `/lesson/${id}`;
  };

  return (
    <div className="navbar bg-black 2xl:h-[10vh]">
      <div className="flex-0">
        {lesson[0]?.last && (
          <div className="col-span-2 pr-2">
            <Link
              to={getNavigationUrl(lesson[0].last, lesson[0].lastSlug)}
              className="inline-flex items-center justify-center w-24 lg:w-48 px-2 py-2 text-xs lg:text-sm font-medium text-white bg-[#67916b] hover:bg-[#5a7e5e] transition-colors rounded-lg"
            >
              Previous {moduleType}
            </Link>
          </div>
        )}
      </div>

      <div className="flex-1">
        {lesson[0]?.next && (
          <div className="col-span-1">
            <Link
              to={getNavigationUrl(lesson[0].next, lesson[0].nextSlug)}
              className="inline-flex items-center justify-center w-24 lg:w-48 px-2 py-2 text-xs lg:text-sm font-medium text-white bg-[#67916b] hover:bg-[#5a7e5e] transition-colors rounded-lg"
            >
              Next {moduleType}
            </Link>
          </div>
        )}
        {!lesson[0]?.next && lesson[0]?.nextCourse?.[0]?.slug && (
          <div className="col-span-1 pr-2">
            <Link
              to={`/course/${lesson[0].nextCourse[0].slug}`}
              className="inline-flex items-center justify-center w-24 lg:w-56 px-2 py-2 text-xs lg:text-sm font-medium text-white bg-orange-800 hover:bg-orange-900 transition-colors rounded-lg"
            >
              Next Course: {lesson[0].nextCourse[0].name}
            </Link>
          </div>
        )}
      </div>

      {lesson[0] && (
        <div className="navbar-start pl-2">
          <ActionButton />
        </div>
      )}
    </div>
  );
}

export default LessonFooter;
