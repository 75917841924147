import React from "react";
import { useEffect } from "react";
import { getAuthor } from "../../features/courses/courseSlice";
import { useSelector, useDispatch } from "react-redux";

function LessonStatus({ lesson }) {
  const { author } = useSelector((state) => state.courses);
  const dispatch = useDispatch();
  useEffect(() => {
    if (lesson[0]?.author) {
      dispatch(getAuthor(lesson[0]?.author));
    }
  }, [dispatch, lesson]);

  return (
    <>
      {lesson[0]?.progress[0]?.status === "Completed" && (
        <h2 className="font-semibold text-lg text-center pt-4 pb-8">
          {lesson[0]?.name}
          <div className="">
            <span className="indicator-item badge badge-success">
              {lesson[0]?.progress[0]?.status}
            </span>
          </div>
        </h2>
      )}
      {lesson[0]?.progress[0]?.status === "In Progress" && (
        <h2 className="font-semibold text-lg text-center pt-4 pb-8">
          {lesson[0]?.name}
          <div className="">
            <span className="indicator-item badge badge-warning">
              {lesson[0]?.progress[0]?.status}
            </span>
          </div>
        </h2>
      )}
      {(!lesson[0]?.progress[0]?.status ||
        lesson[0]?.progress[0]?.status === "Not Started") && (
        <h2 className="font-semibold text-lg text-center pt-4 pb-8">
          {lesson[0]?.name}
          <div className="">
            <span className="indicator-item badge badge-error">
              Not Started
            </span>
          </div>
        </h2>
      )}

      {lesson[0]?.author && author[0] && (
        <div className="bg-[#1e1e1e] text-center mb-8 py-3 border border-purple-300 -mt-4 mx-32">
          <p className="text-sm py-1 ">
            Author:{" "}
            <a href={author[0].url} target="_blank" rel="noopener noreferrer	 ">
              <span className="hover:underline  text-purple-300">
                {author[0].name}
              </span>
            </a>
          </p>
          <p className="text-sm px-2 pt-1">{author[0].bio}</p>
        </div>
      )}
    </>
  );
}

export default LessonStatus;
