import React from "react";
import { GiCookie } from "react-icons/gi";
import { FaSnowflake } from "react-icons/fa";

const Welcome = () => {
  return (
    <div className="relative p-8 rounded-xl bg-gradient-to-br from-zinc-900 via-zinc-900 to-zinc-800">
      {/* Animated snowflakes */}
      <div className="absolute inset-0 overflow-hidden">
        {[...Array(8)].map((_, i) => (
          <FaSnowflake
            key={i}
            className="absolute text-blue-100/10 animate-float-slow"
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              fontSize: `${Math.random() * 12 + 8}px`,
              animationDelay: `${Math.random() * 5}s`,
              animationDuration: `${Math.random() * 3 + 4}s`,
            }}
          />
        ))}
      </div>

      {/* Main content */}
      <div className="relative z-10 max-w-4xl mx-auto space-y-8">
        {/* Header */}
        <div className="text-center">
          <div className="flex items-center justify-center gap-4">
            <GiCookie className="text-yellow-300 w-12 h-12 animate-bounce" />
            <h1 className="text-2xl md:text-4xl font-bold text-yellow-300">
              Welcome to Gingerbread Village
            </h1>
          </div>
          <p className="text-xl mt-4 text-yellow-100">
            Where sugar-dusted streets and spice-scented winds create holiday
            magic all year round!
          </p>
        </div>

        {/* Story */}
        <p className="text-gray-200 text-lg text-center leading-relaxed">
          The Winter Wonderland Festival is just around the corner, but our
          beloved village is in a flurry of excitement and worry! The candy cane
          lanes need decorating, the gingerbread houses need finishing touches,
          and the festive feast isn't going to bake itself!
        </p>

        {/* Mayor's message */}
        <div className="bg-gradient-to-br from-amber-900/40 to-amber-800/40 backdrop-blur-sm border border-amber-700/30">
          <div className="card-body p-6">
            <p className="text-amber-200 italic mb-4 text-lg">
              Mayor Snickerdoodle hurries over, her cookie medallion jingling
              with each step...
            </p>
            <blockquote className="border-l-4 border-amber-400/40 pl-4">
              <p className="text-amber-100 font-medium text-lg">
                "Oh, dearest friend! You've arrived just in time! Our annual
                Winter Wonderland Festival is approaching faster than melting
                icing, and we're all in quite the sugar scramble! Your help
                could be the sprinkle of magic we need!"
              </p>
            </blockquote>
          </div>
        </div>

        {/* Final text */}
        <p className="text-gray-200 text-lg text-center leading-relaxed">
          With a flourish, she presents you with a cookie-shaped map and a
          scroll of tasks.
          <span className="italic ml-2 text-gray-300">
            "Will you help us spread some holiday cheer and make this festival
            the sweetest one yet?"
          </span>
        </p>
      </div>

      {/* Bottom decorative element */}
      <div className="absolute bottom-0 left-0 w-full h-px bg-gradient-to-r from-transparent via-amber-400/20 to-transparent" />
    </div>
  );
};

export default Welcome;
