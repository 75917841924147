import React from "react";
import { Helmet } from "react-helmet";

const SectionHeader = ({ children }) => (
  <h2 className="text-3xl font-bold text-primary mb-4">{children}</h2>
);

const SectionContent = ({ children }) => (
  <p className="text-base-content mb-4 leading-relaxed">{children}</p>
);

const FeatureCard = ({ icon: Icon, title, children }) => (
  <div className="card bg-base-200 shadow-xl">
    <figure className="px-10 pt-10">
      <Icon className="w-16 h-16 text-primary" />
    </figure>
    <div className="card-body">
      <h3 className="card-title text-xl font-semibold">{title}</h3>
      <p>{children}</p>
    </div>
  </div>
);

const CodingIllustration = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 300"
    className="w-full h-auto"
  >
    {/* Monitor */}
    <rect x="50" y="30" width="400" height="240" rx="10" fill="#475569" />
    <rect x="70" y="50" width="360" height="200" rx="5" fill="#0f172a" />

    {/* Code lines */}
    <g fill="#38bdf8">
      <rect x="90" y="70" width="120" height="10" rx="2" />
      <rect x="90" y="90" width="180" height="10" rx="2" />
      <rect x="90" y="110" width="160" height="10" rx="2" />
      <rect x="120" y="130" width="200" height="10" rx="2" />
      <rect x="120" y="150" width="180" height="10" rx="2" />
      <rect x="90" y="170" width="140" height="10" rx="2" />
      <rect x="90" y="190" width="200" height="10" rx="2" />
      <rect x="90" y="210" width="160" height="10" rx="2" />
    </g>
  </svg>
);

const About = () => {
  return (
    <div className="min-h-screen w-full bg-base-100">
      <Helmet>
        <title>About Camp Apex | Learn Apex with Hands-On Practice</title>
        <meta
          name="description"
          content="Discover why Camp Apex was built and how it helps aspiring Salesforce developers learn Apex through hands-on practice and real-world challenges."
        />
        <meta
          property="og:title"
          content="About Camp Apex | Learn Apex with Hands-On Practice"
        />
        <meta
          property="og:description"
          content="Learn about the creation of Camp Apex, a platform designed for hands-on Apex learning with real coding challenges. Discover the technology stack and the story behind it."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://campapex.org/about" />
        <meta
          property="og:image"
          content="https://campapex.org/assets/hero6.png"
        />
        <link rel="canonical" href="https://campapex.org/about" />
      </Helmet>

      <div className="hero bg-base-200"></div>

      <div className="container mx-auto px-4 pt-16">
        <div className="grid md:grid-cols-2 gap-12 ">
          <div>
            <SectionHeader>Why I Built This</SectionHeader>
            <SectionContent>
              Honestly, I'm a little nuts for putting this much time and energy
              into a free resource. But, I believe the most authentic approach
              to becoming a developer is by getting hands-on with a keyboard and
              writing actual code. I also believe education should be accessible
              to everyone.
            </SectionContent>
            <SectionContent>
              If you're investing your time to learn a new skill, I'd bet your
              time is better spent learning from experience. Writing real code
              as you learn is the way to do that.
            </SectionContent>
            <SectionContent>
              With Camp Apex, coding lessons have been divided into manageable,
              bite-sized sections, each paired with an Apex coding challenge to
              solve within the site. These challenges are designed to reinforce
              your learning and progressively build upon one another.
            </SectionContent>
          </div>
          <div className="flex items-center justify-center">
            <CodingIllustration />
          </div>
        </div>

        <div className="mb-16">
          <SectionHeader>Meet the Creator</SectionHeader>
          <div className="flex items-center space-x-6 pt-2 mb-6">
            <div className="avatar">
              <div className="w-24 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">
                <img
                  src="https://camp-apex.s3.us-east-2.amazonaws.com/Misc/1720718725997.jpeg"
                  alt="Saman"
                />
              </div>
            </div>
            <div>
              <h3 className="text-2xl font-semibold">Saman</h3>
              <p className="text-base-content/70">
                Senior Software Engineer & Delivery Lead at Okta | Creator of
                CampApex.org
              </p>
            </div>
          </div>
          <SectionContent>
            I'm{" "}
            <a
              className="link link-primary"
              href="https://samanattar.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Saman
            </a>
            . I just got married to my wife Sheeva, in March 2024. We have 2
            cats, Garlic & Olive. I also love to{" "}
            <a
              className="link link-primary"
              href="https://vsco.co/saman-attar/gallery"
              target="_blank"
              rel="noopener noreferrer"
            >
              cook
            </a>
            . <br /> <br />I built this site in 2022 and released it in 2023. It
            took a lot longer than I thought to build and producing the content
            has taken even longer. I've learned a lot and making this site has
            brought me a lot of happiness and fulfilment. I hope it can get you
            to where you want to be.
            <br />
            <br />
            Anyway, thanks for giving the site a try and let me know if it falls
            short.{" "}
            <a
              className="link link-primary"
              href="https://www.linkedin.com/in/saman-attar/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Connect with me on Linkedin.
            </a>
            <br />
          </SectionContent>
        </div>

        <SectionHeader>How I Built This</SectionHeader>
        <div className="grid md:grid-cols-3 gap-6 pb-16">
          <FeatureCard
            icon={() => (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-16 h-16"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
                />
              </svg>
            )}
            title="Tech Stack"
          >
            This is a MERN (MongoDB, Express, React with Redux, NodeJS) stack
            app. The UI was built with Tailwind and DaisyUI. The code editor is
            Monaco Editor, the same editor that powers VS Code.
          </FeatureCard>
          <FeatureCard
            icon={() => (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-16 h-16"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.25 6.75L22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3l-4.5 16.5"
                />
              </svg>
            )}
            title="Functionality"
          >
            A secure OAuth2 connection with your SFDC org is established when
            you login. This allows the site to compile and execute your code
            submission along with our pre-written test class within your
            Salesforce org.
          </FeatureCard>
          <FeatureCard
            icon={() => (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-16 h-16"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.362 5.214A8.252 8.252 0 0112 21 8.25 8.25 0 016.038 7.048 8.287 8.287 0 009 9.6a8.983 8.983 0 013.361-6.867 8.21 8.21 0 003 2.48z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 18a3.75 3.75 0 00.495-7.467 5.99 5.99 0 00-1.925 3.546 5.974 5.974 0 01-2.133-1A3.75 3.75 0 0012 18z"
                />
              </svg>
            )}
            title="Support"
          >
            The site is free and the best way to support is by using it, sharing
            it, and providing feedback. If you're interested and have the means
            to do so, you can also{" "}
            <a
              className="link link-primary"
              href="https://www.buymeacoffee.com/CampApexDotOrg"
              target="_blank"
              rel="noopener noreferrer"
            >
              donate here
            </a>
            .
          </FeatureCard>
        </div>

        {/* <div className="alert alert-info shadow-lg">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              className="stroke-current flex-shrink-0 w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
            <span>
              When you connect your SFDC instance to Camp Apex, a composite key
              of your org's Id and user's 18-digit Id is kept. This is used to
              track & retain your progress in Camp Apex when you log back in
              next time.
            </span>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default About;
