import { useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { useSelector } from "react-redux";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import { FiCheckCircle, FiTarget } from "react-icons/fi";
import { FaCoins } from "react-icons/fa";

function CompetitionMarkdown({ challenge }) {
  const { executionStatus, challengeProgress } = useSelector(
    (state) => state.competitions
  );

  const StatusIcon = ({ status }) => {
    switch (status) {
      case "Accepted":
        return (
          <div className="tooltip font-base" data-tip="Completed">
            <FiCheckCircle color="green" className="ml-2" />
          </div>
        );
      case "Attempted":
        return (
          <div className="tooltip font-base" data-tip="Attempted">
            <FiTarget color="orange" className="ml-2" />
          </div>
        );
      case "Reset":
        return (
          <div className="tooltip font-base" data-tip="Attempted & Reset">
            <FiTarget color="orange" className="ml-2" />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div
      className="bg-zinc-900 text-slate-100 overflow-y-auto text-left px-8 pt-14"
      id="challenge-panel"
    >
      <div className="grid grid-cols-2 gap-0 pb-4">
        <div className="  text-lg flex items-center">
          <p className="font-extrabold">{challenge?.[0]?.name}</p>
          <StatusIcon
            status={
              executionStatus?.submissionResults?.status ||
              challengeProgress?.status
            }
          />
        </div>
        <div className="row-start-2 flex items-center text-sm">
          {challenge?.[0]?.points}
          <FaCoins color="gold" className="ml-1" />
        </div>
      </div>

      <ReactMarkdown
        children={challenge?.[0]?.challenge}
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeRaw]}
        components={{
          table: ({ node, ...props }) => (
            <table
              className="border-collapse border border-emerald-500 bg-emerald-900/20 my-4 w-full"
              {...props}
            />
          ),
          thead: ({ node, ...props }) => (
            <thead className="bg-emerald-900/40" {...props} />
          ),
          th: ({ node, ...props }) => (
            <th
              className="border border-emerald-500 p-2 text-emerald-200"
              {...props}
            />
          ),
          td: ({ node, ...props }) => (
            <td className="border border-emerald-500 p-2" {...props} />
          ),
          code({ node, inline, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || "");
            return !inline && match ? (
              <SyntaxHighlighter
                children={String(children).replace(/\n$/, "")}
                style={vscDarkPlus}
                language={match[1]}
                PreTag="div"
                {...props}
              />
            ) : (
              <code className={className} {...props}>
                {children}
              </code>
            );
          },
        }}
      />
    </div>
  );
}

export default CompetitionMarkdown;
