import React, { useEffect } from "react";
import { useParams, useNavigate, generatePath } from "react-router-dom";

import usePracticeData from "../../../hooks/usePracticeData";
import Header from "./Header";
import RoleSelector from "./RoleSelector";
import CourseTable from "./CourseTable";
//import FlowIntoApexPage from "../FlowIntoApex/FlowIntoApexPage";

const PracticeList = () => {
  const { collection, role, course } = useParams();
  const navigate = useNavigate();

  const {
    initialRole,
    selectedRole,
    practiceData,
    showSpinner,
    selectedCourse,
    setSelectedCourse,
    handleRoleChange,
  } = usePracticeData();

  useEffect(() => {
    if (course) {
      setSelectedCourse(course);
    }
  }, [course, setSelectedCourse]);

  useEffect(() => {
    if (collection) {
      console.log(`Handling role change: ${collection}`);
      handleRoleChange(collection);
    }
  }, [collection, handleRoleChange]);

  useEffect(() => {
    if (selectedCourse && role) {
      const formattedPath = `/practice/${role.toLowerCase()}/${selectedCourse}`
        .replace(/%20/g, "-")
        .replace(/\s+/g, "-");

      navigate(formattedPath, { replace: true });
    }
  }, [selectedCourse, role, navigate]);

  if (showSpinner) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        Loading...
      </div>
    );
  }

  const dataArray = Array.isArray(practiceData.data) ? practiceData.data : [];

  let matchedData = dataArray.find((data) =>
    data.type.toLowerCase().includes(selectedRole.toLowerCase())
  );

  matchedData = matchedData
    ? {
        ...matchedData,
        courses: [...matchedData.courses].sort((a, b) =>
          a.courseName.localeCompare(b.courseName)
        ),
      }
    : null;

  const handleCourseSelect = (courseName) => {
    setSelectedCourse(courseName);
  };

  return (
    <div className="min-h-screen cursor-default bg-zinc-900 p-8">
      <Header
        initialRole={initialRole}
        selectedRole={selectedRole}
        practiceData={practiceData}
        handleRoleChange={handleRoleChange}
      />
      <div className="pt-8">
        {initialRole !== "{Your Role}" && (
          <RoleSelector
            matchedData={matchedData}
            selectedCourse={selectedCourse}
            setSelectedCourse={handleCourseSelect}
          />
        )}
      </div>
      <div className="pt-8">
        {matchedData && (
          <div>
            {selectedCourse
              ? matchedData.courses
                  .filter(
                    (course) =>
                      course.courseName.replace(/\s+/g, "-").toLowerCase() ===
                      selectedCourse
                  )
                  .map((course, courseIndex) => (
                    <CourseTable
                      key={courseIndex}
                      course={course}
                      selectedRole={selectedRole}
                    />
                  ))
              : matchedData.courses.map((course, courseIndex) => (
                  <CourseTable
                    key={courseIndex}
                    course={course}
                    selectedRole={selectedRole}
                    onSelect={() => handleCourseSelect(course.courseName)}
                  />
                ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default PracticeList;
