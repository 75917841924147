import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import courseService from "./courseService";

// NOTE: use a extractErrorMessage function to save some repetition
// import { extractErrorMessage } from "../../utils";

// NOTE: no need for isLoading, isSuccess, isError or message as we can leverage
// our AsyncThunkAction and get Promise reolved or rejected messages at
// component level
const initialState = {
  courses: null,
  course: [],
  lesson: [],
  lessonList: [],
  executionStatus: [],
  courseDetails: {},
  collections: {},
  courseStatus: {},
  showSpinner: false,
  packageInstalled: true,
  installDataResponse: [],
  events: [],
  author: {},
  selectedRole: "{Your Role}",
  practiceData: [],
};

export const getCourses = createAsyncThunk(
  "courses",
  async (courseType, thunkAPI) => {
    try {
      return await courseService.getCourses(courseType);
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getLessonsForCourse = createAsyncThunk(
  "course/get",
  async (slug, thunkAPI) => {
    try {
      //const token = thunkAPI.getState().auth.user.token;
      return await courseService.getLessonsForCourse(slug);
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue();
    }
  }
);

export const setSolution = createAsyncThunk("solution", (value, thunkAPI) => {
  return value;
});

export const setOpenCollection = createAsyncThunk(
  "collection",
  (value, thunkAPI) => {
    return value;
  }
);

export const getLesson = createAsyncThunk(
  "lesson/get",
  async (challengeIdOrSlug, thunkAPI) => {
    try {
      //const token = thunkAPI.getState().auth.user.token;
      return await courseService.getLesson(challengeIdOrSlug);
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue();
    }
  }
);

export const executeLesson = createAsyncThunk(
  "executeLesson/post",
  async (payload, thunkAPI) => {
    try {
      return await courseService.executeLesson(payload);
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue();
    }
  }
);

export const executeReadingCompleted = createAsyncThunk(
  "executeReadingCompleted/post",
  async (payload, thunkAPI) => {
    try {
      return await courseService.executeReadingCompleted(payload);
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue();
    }
  }
);

export const executeCodeReset = createAsyncThunk(
  "executeCodeReset/post",
  async (payload, thunkAPI) => {
    try {
      return await courseService.executeCodeReset(payload);
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue();
    }
  }
);

export const semanticValidationException = createAsyncThunk(
  "",
  async (payload, thunkAPI) => {
    let errorMsg = payload;
    payload = {
      data: {
        success: false,
        compileProblem: errorMsg,
      },
    };
    return payload;
  }
);

export const installData = createAsyncThunk(
  "installData/get",
  async (dataKey, thunkAPI) => {
    try {
      return await courseService.getInstallData(dataKey);
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getPackageStatus = createAsyncThunk(
  "packageStatus/get",
  async (packageId, thunkAPI) => {
    try {
      return await courseService.getPackageStatus(packageId);
    } catch (error) {
      console.log(error);

      return thunkAPI.rejectWithValue();
    }
  }
);

export const getEvents = createAsyncThunk(
  "getEvents/get",
  async (_, thunkAPI) => {
    try {
      return await courseService.getEvents();
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getAuthor = createAsyncThunk(
  "getAuthor",
  async (authorId, thunkAPI) => {
    try {
      return await courseService.getAuthor(authorId);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchPracticeProblems = createAsyncThunk(
  "practice/fetchPracticeData",
  async (_, thunkAPI) => {
    try {
      return await courseService.fetchPracticeData();
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const deployMetadata = createAsyncThunk(
  "metadata/deploy",
  async (metadataType, thunkAPI) => {
    try {
      return await courseService.deployMetadata(metadataType);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const deployAdvent = createAsyncThunk(
  "metadata/deployAdvent",
  async (metadataType, thunkAPI) => {
    try {
      return await courseService.deployAdvent(metadataType);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// NOTE: removed loading, isSuccess state as it can be infered from presence or
// absence of tickets for simpler state management with no need for a reset
// function

export const courseSlice = createSlice({
  name: "courses",
  initialState,
  reducers: {
    setSelectedRole(state, action) {
      state.selectedRole = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCourses.pending, (state) => {
        state.showSpinner = true;
        state.courses = null;
        state.courseDetails = [];
        state.lessonList = [];
        state.collections = null;
      })
      .addCase(getCourses.fulfilled, (state, action) => {
        state.showSpinner = false;
        state.courses = action.payload["courses"];
        state.collections = action.payload["collection"];
        state.courseStatus = action.payload["courseCompletionData"];
      })
      .addCase(getLessonsForCourse.pending, (state, action) => {
        state.showSpinner = true;
        state.courseDetails = [];
        state.lessonList = [];
      })
      .addCase(getLessonsForCourse.fulfilled, (state, action) => {
        state.showSpinner = false;
        state.courseDetails = action.payload[0][0].courseDetails;
        state.lessonList = action.payload[0].slice(1, action.payload[0].length);
      })
      .addCase(getLesson.fulfilled, (state, action) => {
        state.lesson = action.payload;
        state.solution = "";
        state.executionStatus = "";
      })
      .addCase(executeLesson.pending, (state, action) => {
        state.showSpinner = true;
      })
      .addCase(executeLesson.fulfilled, (state, action) => {
        state.showSpinner = false;
        state.executionStatus = action.payload;
        state.lesson[0].progress = [action.payload.submissionResults];
      })
      .addCase(executeLesson.rejected, (state, action) => {
        state.showSpinner = false;
        state.executionStatus = "AUTH ERROR";
        state.lesson[0].progress = "";
      })
      .addCase(executeReadingCompleted.pending, (state, action) => {
        state.showSpinner = true;
      })
      .addCase(executeReadingCompleted.fulfilled, (state, action) => {
        state.showSpinner = false;
        state.executionStatus = action.payload;
        state.lesson[0].progress = [action.payload.submissionResults];
      })
      .addCase(executeReadingCompleted.rejected, (state, action) => {
        state.showSpinner = false;
        state.executionStatus = "AUTH ERROR";
        state.lesson[0].progress = "";
      })
      // TODO: MOVE SOLUTION TO IT'S OWN SLICE & STATE. It's causing the Lesson to rerender
      .addCase(setSolution.fulfilled, (state, action) => {
        state.solution = action.payload;
      })
      .addCase(setOpenCollection.fulfilled, (state, action) => {
        state.openCollection = action.payload;
      })
      .addCase(semanticValidationException.fulfilled, (state, action) => {
        state.executionStatus = action.payload;
      })
      .addCase(getPackageStatus.fulfilled, (state, action) => {
        state.packageInstalled = action.payload[0].isInstalled;
      })
      .addCase(installData.fulfilled, (state, action) => {
        state.installDataResponse = action.payload[0];
        state.showSpinner = false;
      })
      .addCase(installData.pending, (state, action) => {
        state.showSpinner = true;
      })
      .addCase(getEvents.fulfilled, (state, action) => {
        state.events = action.payload.data;
      })
      .addCase(getAuthor.fulfilled, (state, action) => {
        state.author = action.payload;
      })
      .addCase(fetchPracticeProblems.pending, (state) => {
        state.showSpinner = true;
      })
      .addCase(fetchPracticeProblems.fulfilled, (state, action) => {
        state.showSpinner = false;
        state.practiceData = action.payload;
      })
      .addCase(fetchPracticeProblems.rejected, (state) => {
        state.showSpinner = false;
      })
      .addCase(deployMetadata.pending, (state) => {
        state.showSpinner = true;
      })
      .addCase(deployMetadata.fulfilled, (state, action) => {
        state.showSpinner = false;
      })
      .addCase(deployMetadata.rejected, (state, action) => {
        state.showSpinner = false;
      })
      .addCase(deployAdvent.pending, (state) => {
        state.showSpinner = true;
      })
      .addCase(deployAdvent.fulfilled, (state, action) => {
        state.showSpinner = false;
      })
      .addCase(deployAdvent.rejected, (state, action) => {
        state.showSpinner = false;
      });
  },
});

export const { setSelectedRole } = courseSlice.actions;
export default courseSlice.reducer;
