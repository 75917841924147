import React from "react";
import { Link } from "react-router-dom";
import { ChevronDown } from "lucide-react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";

const Header = ({
  initialRole,
  selectedRole,
  handleRoleChange,
  practiceData,
}) => {
  const roles =
    practiceData.data?.map((item) => ({
      slug: item.slug,
      name: item.type || item.slug,
      description: item.description,
    })) || [];

  const selectedRoleName =
    roles.find((role) => role.slug === selectedRole)?.name || selectedRole;

  const description =
    roles.find((role) => role.name === selectedRole)?.description || "";

  const filteredRoles = roles.filter((role) => role.slug !== selectedRole);

  const headerPaddingClass =
    selectedRole === "Flow Into Apex"
      ? "flex flex-col items-center my-12 mb-64"
      : "flex flex-col items-center my-8";

  return (
    <header className={headerPaddingClass}>
      <h1 className="text-4xl font-bold text-white mb-4">
        <div className="dropdown dropdown-hover inline-block relative">
          <label
            tabIndex={0}
            className="cursor-pointer text-purple-300 text-4xl flex items-center hover:text-purple-400 transition-colors duration-300"
          >
            {initialRole === "{Your Role}" ? (
              <span className="text-gray-400">{initialRole}</span>
            ) : (
              selectedRoleName
            )}

            <ChevronDown className="ml-2 h-6 w-6" />
          </label>

          <ul
            tabIndex={0}
            className="dropdown-content menu p-2 shadow bg-gray-800 rounded-lg w-56 text-base"
          >
            {filteredRoles.map((role) => (
              <li key={role.slug}>
                <button
                  onClick={() => handleRoleChange(role.slug)}
                  className="w-full text-left py-2 px-4 hover:bg-gray-700 rounded text-white"
                >
                  {role.name}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </h1>
      {selectedRole === "Flow Into Apex" && (
        <div className="text-center mb-3 ">
          <p className="text-md text-zinc-300 mb-8"></p>
          <div className="bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 p-1 rounded-lg inline-block">
            <div className="bg-zinc-900 px-6 py-3 rounded-lg ">
              <Link
                to={`https://www.linkedin.com/in/kimberlylsmall/`}
                className="hover:text-blue-300 text-xl font-bold bg-gradient-to-r from-blue-400 via-purple-400 to-pink-400 bg-clip-text text-transparent hover:underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                By Kimberly Small
              </Link>
              <p className="text-zinc-400 text-sm">
                Salesforce Developer | Snap2Case Founder | 8x Certified
              </p>
            </div>
          </div>
        </div>
      )}

      <p className="text-md text-center text-gray-200 pt-4 w-7/12">
        <ReactMarkdown
          className="text-center"
          children={description}
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeRaw]}
        />
      </p>
    </header>
  );
};

export default Header;
