import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  registerDisplayName,
  getStats,
  clearRegisterDisplayNameMessage,
} from "../../features/competition/competitionSlice";

const DisplayNameCheck = ({ children }) => {
  const dispatch = useDispatch();
  const [displayName, setDisplayName] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { userStats, registerDisplayNameMessage, showSpinner } = useSelector(
    (state) => state.competitions
  );

  // Initialize and check for display name on mount
  useEffect(() => {
    dispatch(getStats());
  }, [dispatch]);

  // Handle modal visibility based on display name existence
  useEffect(() => {
    if (userStats && !userStats.userInfo?.displayName) {
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
    }
  }, [userStats]);

  // Cleanup registration message on unmount
  useEffect(() => {
    return () => {
      dispatch(clearRegisterDisplayNameMessage());
    };
  }, [dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (displayName.trim()) {
      try {
        await dispatch(registerDisplayName(displayName.trim())).unwrap();
        await dispatch(getStats()).unwrap();
      } catch (err) {
        console.error("Error registering display name:", err);
      }
    }
  };

  const renderErrorMessage = () => {
    if (!registerDisplayNameMessage) return null;

    if (typeof registerDisplayNameMessage === "string") {
      return (
        <div className="text-red-400 text-sm mt-2">
          {registerDisplayNameMessage}
        </div>
      );
    }

    if (registerDisplayNameMessage.NewDisplayName) return null;

    if (registerDisplayNameMessage.Error) {
      return (
        <div className="text-red-400 text-sm mt-2">
          {registerDisplayNameMessage.Error}
          {registerDisplayNameMessage.FixRoute && (
            <Link
              to={registerDisplayNameMessage.FixRoute}
              className="text-blue-400 hover:underline ml-1"
            >
              {registerDisplayNameMessage.FixLabel}
            </Link>
          )}
        </div>
      );
    }

    return null;
  };

  // Modal backdrop with blur effect
  const modalBackdrop = isModalOpen && (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div className="bg-zinc-800 rounded-lg shadow-xl max-w-md w-full mx-auto animate-fade-in">
        <div className="p-6">
          <h2 className="text-xl font-bold text-white mb-4">
            Choose Your Display Name
          </h2>

          <p className="text-gray-300 mb-6">
            Before you can participate in challenges, you need to set a display
            name. This will be visible to other participants.
          </p>

          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="relative">
              <input
                type="text"
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
                placeholder="Enter display name"
                className="w-full px-4 py-2 bg-zinc-700 border border-zinc-600 rounded-lg 
                         text-white placeholder-gray-400 focus:outline-none focus:border-blue-500
                         focus:ring-1 focus:ring-blue-500"
                maxLength={30}
                disabled={showSpinner}
              />
            </div>

            {renderErrorMessage()}

            <div className="flex justify-end mt-6">
              <button
                type="submit"
                disabled={!displayName.trim() || showSpinner}
                className="btn btn-primary"
              >
                {showSpinner ? (
                  <span className="loading loading-spinner"></span>
                ) : (
                  "Set Display Name"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {children}
      {modalBackdrop}
    </>
  );
};

export default DisplayNameCheck;
