import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { FaCheckCircle } from "react-icons/fa";
import { MdCancel } from "react-icons/md";

function ResultPanel() {
  const { executionStatus } = useSelector((state) => state.competitions);
  const [activeTestResult, setActiveTestResult] = useState("");

  let executionResults = [];

  if (executionStatus?.data?.exceptionMessage) {
    try {
      executionResults = JSON.parse(
        executionStatus?.data?.exceptionMessage.replace(
          "TestResultException: ",
          ""
        )
      );
    } catch (e) {
      executionResults = executionStatus?.data?.exceptionMessage;
    }
  }

  const handleClick = (testResultId) => {
    setActiveTestResult(testResultId);
  };

  console.log(executionStatus?.data?.compileProblem);

  const renderContent = () => {
    let panelName = "Result Panel";

    if (executionResults.length && Array.isArray(executionResults)) {
      return (
        <div className="join join-vertical w-full">
          {executionResults?.map((result) => (
            <div key={result.order} onClick={() => handleClick(result.order)}>
              <div
                className={`collapse collapse-arrow join-item ${
                  activeTestResult === result.order ? "collapse-open" : ""
                }`}
              >
                <div
                  className="collapse-title text-md font-xs"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <p className="pr-2">Scenario {result.order}</p>{" "}
                  {result.pass ? (
                    <FaCheckCircle color="green" />
                  ) : (
                    <MdCancel color="red" />
                  )}
                </div>

                <div className="collapse-content text-sm">
                  {
                    <>
                      <p className="pb-5 pl-4 underline">Scenario:</p>
                      <p className="pb-5 pl-4"> {result.scenario}</p>
                    </>
                  }
                  {result.message && (
                    <div className="pl-4">
                      <p className="underline pb-2">Message:</p>
                      {result.message.split("\n").map((line, i) => (
                        <p className="pt-1" key={i}>
                          {line}
                        </p>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      );
    } else if (executionStatus === "AUTH ERROR") {
      return (
        <>
          <h1 className="font-bold text-lg text-red-300 pb-1">Auth Error</h1>
          <h3 className="text-sm text-red-300">
            You've been logged out due to inactivity. Save your work locally and
            login to continue.
          </h3>
        </>
      );
    } else if (executionStatus?.data?.compileProblem) {
      return (
        <>
          <h1 className="font-bold text-lg">Compilation Error:</h1>
          <p className="text-sm">{executionStatus?.data?.compileProblem}</p>
        </>
      );
    } else if (executionStatus?.data?.exceptionMessage) {
      return (
        <>
          <h1 className="font-bold text-lg">Runtime Error:</h1>
          <p className="text-sm">{executionStatus?.data?.exceptionMessage}</p>
        </>
      );
    } else {
      return (
        <>
          <h1 className="font-bold text-lg">{panelName}</h1>
          <h3 className="text-sm">Hit the run button to test your code!</h3>
        </>
      );
    }
  };

  return <div>{renderContent()}</div>;
}

export default ResultPanel;
