import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { FaCoins } from "react-icons/fa";
import moment from "moment-timezone";
import "add-to-calendar-button";

// Redux actions
import {
  getContest,
  getPointsDistribution,
  register,
} from "../../features/competition/competitionSlice";
import { getUser } from "../../features/auth/authSlice";

// Components
import CountdownTimer from "./CountdownTimer";
import ProblemSet from "./ProblemSet";
import ContestantResults from "./ContestantResults";

import useCountdown from "./hooks/useCountdown";

function CompetitionInfo() {
  const { pointsDistribution, contest, registrationStatus, generalError } =
    useSelector((state) => state.competitions);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { tag } = useParams();
  const [accessCode, setAccessCode] = useState("");

  useEffect(() => {
    try {
      dispatch(getContest(tag));
      dispatch(getUser());
      dispatch(getPointsDistribution("Apex Algos"));
    } catch (err) {
      console.log(err);
    }
  }, [tag, registrationStatus, dispatch]);

  let pointsJson = {};
  if (pointsDistribution?.points) {
    pointsJson = JSON.parse(pointsDistribution?.points)?.points_distribution;
  }
  /* Calender Logic */
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  let calURL = "https://www.CampApex.org/compete/" + contest?.contest?.tag;
  const userDateTime = moment.utc(contest?.contest?.startdate).tz(timeZone);
  const startDate = userDateTime.format("YYYY-MM-DD");
  const startTime = userDateTime.format("HH:mm");
  const endDate = userDateTime.add(2, "hours").format("YYYY-MM-DD");
  const endTime = userDateTime.format("HH:mm");

  const isActive = useCountdown(contest?.contest?.startdate);

  useEffect(() => {
    if (isActive) {
      dispatch(getContest(tag)); // Re-fetch
    }
  }, [isActive, tag, dispatch]);

  function handleRegistration() {
    dispatch(
      register({ contestId: contest?.contest?._id, accessCode: accessCode })
    );
  }

  return (
    <div className="grid grid-cols-5 bg-zinc-900">
      <div className="col-span-5">
        <CompetitionHeader contest={contest} />
      </div>
      <div className="col-span-4">
        <CompetitionDescription description={contest?.contest?.description} />
      </div>
      <div className="col-span-1">
        <ValorPoints pointsJson={pointsJson} />
      </div>
      <div className="col-span-5 align-right">
        <RegistrationSection
          registrationStatus={registrationStatus}
          contest={contest}
          user={user[0]}
          handleRegistration={handleRegistration}
          generalError={generalError?.Error}
          accessCode={accessCode}
          setAccessCode={setAccessCode}
        />
      </div>
      {contest?.contest?.enddate > new Date().toISOString() && (
        <div className="col-span-5 pb-9">
          <CalendarAddSection
            name="Camp Apex CloudStrike Competition"
            calURL={calURL}
            startDate={startDate}
            endDate={endDate}
            startTime={startTime}
            endTime={endTime}
            timeZone={timeZone}
          />
        </div>
      )}

      <div className="col-span-3">
        <ProblemSetSection />
      </div>
      <div className="col-span-2">
        <ContestantResults />
      </div>
    </div>
  );
}

function CompetitionHeader({ contest }) {
  const startDate = contest?.contest?.startdate;
  const endDate = contest?.contest?.enddate;
  const now = new Date().toISOString();

  const isActive = startDate < now && endDate > now;
  const isHolding = startDate > now;
  const isFinished = endDate < now;

  // TODO: Show a message indicating the contest is complete for the user.

  let activeText =
    contest?.registrationStatus?.status === "Finished"
      ? "You did it! Final results available in:"
      : "HAPPENING NOW. Ends in: ";
  let pendingText = "Contest starts in: ";
  let pastText = "This contest has ended.";

  return (
    <div className="row-span-1 col-span-5 font-bold font-mono text-2xl md:text-3xl flex flex-col justify-center items-center pt-16 bg-zinc-900">
      <div className="items-center justify-center px-2 lg:px-0">
        {contest?.contest?.name} - {contest?.contest?.type}
      </div>
      <div className="flex items-center justify-center text-lg pt-2  ">
        {isActive && (
          <>
            <div className="pr-2">{activeText} </div>
            <CountdownTimer targetUtcDate={contest?.contest?.enddate || 0} />
          </>
        )}
        {isHolding && (
          <>
            <div className="pr-2">{pendingText} </div>
            <CountdownTimer
              targetUtcDate={contest?.contest?.startdate || 0}
              showDays={true}
            />
          </>
        )}
        {isFinished && <>{pastText}</>}
      </div>
    </div>
  );
}

function CompetitionDescription({ description }) {
  return (
    <div className="row-span-5 col-span-4 overflow-x-auto max-h-1/3 pt-14 lg:px-48 px-9 bg-zinc-900">
      <ReactMarkdown
        className="pt-2 text-xs lg:text-base "
        children={description}
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeRaw]}
      />
    </div>
  );
}

function ValorPoints({ pointsJson }) {
  return (
    <div className="row-span-3 flex items-right justify-center py-9 lg:py-0 bg-zinc-900">
      <div className="text-xs lg:text-sm overflow-x-scroll">
        <p className="text-base-300 text-md md:text-xl flex items-end justify-center">
          Points
        </p>
        <table className="table ">
          <tbody>
            {Object.entries(pointsJson).map(([position, points], index) => (
              <tr key={index}>
                <td className="text-xs md:text-sm bg-zinc-900">{position}</td>
                <td className="text-right text-xs md:text-sm bg-zinc-900">
                  {points}
                </td>
                <td className="text-right text-xs md:text-sm  bg-zinc-900">
                  <FaCoins color="gold" className="-ml-6" />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

function CalendarAddSection({
  name,
  calURL,
  startDate,
  endDate,
  startTime,
  endTime,
  timeZone,
}) {
  return (
    <div className="bg-zinc-900 font-bold font-mono flex justify-center items-center">
      <add-to-calendar-button
        name={name}
        options="'Apple','Google','iCal','Outlook.com','Microsoft 365'"
        location={calURL}
        startDate={startDate}
        endDate={endDate}
        startTime={startTime}
        endTime={endTime}
        timeZone={timeZone}
        lightMode="dark"
      ></add-to-calendar-button>
    </div>
  );
}

function RegistrationSection({
  registrationStatus,
  contest,
  user,
  handleRegistration,
  generalError,
  accessCode,
  setAccessCode,
}) {
  const renderRegistrationStatus = () => {
    const startDate = contest?.contest?.startdate;
    const endDate = contest?.contest?.enddate;
    const now = new Date().toISOString();

    const isActive = startDate < now && endDate > now;
    const isHolding = startDate > now;
    const isFinished = endDate < now;

    let registrationError = registrationStatus?.Error && user?.instanceUrl;
    let registeredForUpcomingContest =
      contest?.registrationStatus && !contest?.challenges && isHolding;
    let mustRegister =
      !contest?.registrationStatus &&
      !registrationStatus?.Error &&
      user?.instanceUrl &&
      !isFinished;

    let mustLogin = !user?.instanceUrl && !isFinished;
    let alreadyCompleted = contest?.registrationStatus?.status === "Finished";

    if (mustLogin) {
      return (
        <Link
          to="/auth"
          target="_blank"
          className="btn btn-yellow flex items-center justify-center register-button px-24 whitespace-nowrap	mt-4"
        >
          Login to Register
        </Link>
      );
    } else if (registrationError) {
      return (
        <>
          <div className="text-orange-300 text-xs md:text-sm ">
            {registrationStatus?.Error}{" "}
            {registrationStatus?.FixRoute && (
              <Link
                to="/auth"
                target="_blank"
                className="text-base-300 underline hover:text-yellow-500"
              >
                {registrationStatus?.FixLabel}
              </Link>
            )}
          </div>
        </>
      );
    } else if (generalError) {
      return <>{generalError.Error}</>;
    } else if (registeredForUpcomingContest) {
      return (
        <div className="text-orange-300 text-xs md:text-sm ">
          Locked and Loaded. You're registered. See you when the competition
          starts.
        </div>
      );
    } else if (mustRegister) {
      return (
        <>
          {contest?.contest?.location !== "Virtual" && (
            <input
              type="text"
              placeholder="Access Code"
              className="input input-bordered w-full max-w-xs bg-zinc-800 mr-1"
              value={accessCode}
              onChange={(e) => setAccessCode(e.target.value)}
            />
          )}

          <Link
            to="#"
            className="btn btn-yellow flex items-center justify-center register-button"
            onClick={handleRegistration}
          >
            Register
          </Link>
        </>
      );
    } else if (alreadyCompleted && isActive) {
      return (
        <div className="text-yellow-300 pt-9  text-xs md:text-sm px-2">
          Nice Work! You've completed this contest.
        </div>
      );
    } else if (isActive) {
      return (
        <div className="text-yellow-300 pt-9  text-xs md:text-sm px-2">
          We're live. Solve these before time runs out!
        </div>
      );
    } else if (isFinished) {
      return (
        <div className="text-orange-300 text-xs md:text-sm pb-9">
          This contest is over. Check out some of the upcoming ones.
        </div>
      );
    }
    return null;
  };

  return (
    <div className="row-span-1 col-span-5 bg-zinc-900 font-bold font-mono text-3xl flex justify-center items-center pb-5">
      {renderRegistrationStatus()}
    </div>
  );
}

function ProblemSetSection() {
  return (
    <div className="border border-0  border-r-2 border-white row-span-1 col-span-3 bg-zinc-900 font-mono text-xs md:text-sm pr-5">
      <ProblemSet />
    </div>
  );
}

export default CompetitionInfo;
