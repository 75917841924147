import React from "react";
import {
  FaCookie,
  FaCandyCane,
  FaWater,
  FaCookieBite,
  FaCloudRain,
  FaCloud,
  FaMusic,
} from "react-icons/fa";

const Characters = () => {
  const alerts = [
    {
      title: "The Grand Gingerbread Bakery",
      description:
        "Master Baker Maple is in a frenzy! The magical oven isn't producing enough gingerbread, and without your help, there won't be enough houses for all the festival visitors.",
      icon: <FaCookie className="w-6 h-6" />,
      color: "bg-amber-900/20 text-amber-100",
    },
    {
      title: "Candycane River",
      description:
        "The annual Candycane Fishing Derby is in jeopardy! Fisherelf Peppermint says the river is running dry.",
      icon: <FaCandyCane className="w-6 h-6" />,
      color: "bg-red-900/20 text-red-100",
    },
    {
      title: "Gumdrop Forest",
      description:
        "Ranger Butterscotch is having trouble keeping track of the different gumdrop trees.",
      icon: <FaWater className="w-6 h-6" />,
      color: "bg-green-900/20 text-green-100",
    },
    {
      title: "Chocolate Chip Mines",
      description:
        "Miner Mocha is in distress! The conveyor belts in the mines are malfunctioning, causing a massive backup of chocolate chips.",
      icon: <FaCookieBite className="w-6 h-6" />,
      color: "bg-orange-900/20 text-orange-100",
    },
    {
      title: "Frosting Falls",
      description:
        "The magnificent Frosting Falls, which supplies icing to the entire village, is running low.",
      icon: <FaCloudRain className="w-6 h-6" />,
      color: "bg-blue-900/20 text-blue-100",
    },
    {
      title: "Marshmallow Meadows",
      description:
        "Farmer Fluff is having trouble with his marshmallow crops. The marshmallows aren't growing evenly.",
      icon: <FaCloud className="w-6 h-6" />,
      color: "bg-pink-900/20 text-pink-100",
    },
    {
      title: "Sugarplum Square",
      description:
        "The Gingerbread Talent Show, a highlight of the Sweetstravaganza, is approaching, but Choreographer Cinnamon is struggling to arrange the performers.",
      icon: <FaMusic className="w-6 h-6" />,
      color: "bg-purple-900/20 text-purple-100",
    },
  ];

  return (
    <div className="grid gap-4 md:grid-cols-2">
      {alerts.map((alert, index) => (
        <div
          key={index}
          className={`card ${alert.color} hover:shadow-xl transition-all duration-300 !bg-zinc-900`}
        >
          <div className="card-body !text-white">
            <div className="flex items-start gap-3">
              <div
                className={`${
                  alert.color.includes("amber")
                    ? "text-amber-100"
                    : alert.color.includes("red")
                    ? "text-red-100"
                    : alert.color.includes("green")
                    ? "text-green-100"
                    : alert.color.includes("orange")
                    ? "text-orange-100"
                    : alert.color.includes("blue")
                    ? "text-blue-100"
                    : alert.color.includes("pink")
                    ? "text-pink-100"
                    : "text-purple-100"
                }`}
              >
                {alert.icon}
              </div>
              <div>
                <h3 className="card-title text-lg font-bold mb-2">
                  {alert.title}
                </h3>
                <p className="opacity-90">{alert.description}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Characters;
